import { graphql, useStaticQuery } from "gatsby";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import { IconCaret } from "@upsolve/ui";
import getFilteredReviewsData from "./getFilteredReviewsData";
import { formatDistanceToNowStrict } from "date-fns";
import ReviewStar from "../../../static/images/bankruptcy-assets/star.svg";
import ReviewStarMobile from "../../../static/images/bankruptcy-assets/starMobile.svg";
import ImageInContext from "../Media/ImageInContext";
import ModalBackground from "../overlays/ModalBackground";

const useReviews = ({ maxReviews }: { maxReviews: number }) => {
  const { pictures } = useStaticQuery(graphql`
    query ReviewsWallPictures {
      pictures: allContentfulReview(
        filter: { image: { id: { ne: null } } }
        sort: { fields: [date], order: ASC }
        limit: 14
      ) {
        nodes {
          image {
            altText
            image {
              fluid(maxWidth: 100) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);
  return {
    reviews: getFilteredReviewsData(pictures?.nodes)
      .reviews.filter((review: TReview) => review.type === "google")
      .slice(0, maxReviews),
  };
};
const googleReviewsURL =
  "https://www.google.com/search?q=upsolve&oq=upsolve&gs_lcrp=EgZjaHJvbWUqBggAEEUYOzIGCAAQRRg7MgYIARBFGDwyBggCEEUYPDIGCAMQRRg8MgYIBBBFGEEyBggFEEUYQTIGCAYQRRg8MgYIBxAuGEDSAQgzMDk3ajBqMagCALACAA&sourceid=chrome&ie=UTF-8#lrd=0x89c25a4fbebde7fd:0x494443fff411eea3,1,,,,";

type TReview = {
  createdAt: Date;
  rating: number;
  reviewAt: Date;
  reviewText: string;
  reviewerName: string;
  reviewerPhotoUrl: string;
  type: string;
};
export const ReviewsWall = ({ maxReviews = 72 }) => {
  const reviewsDiv = useRef<HTMLDivElement | null>(null);
  const [reviewInView, setReviewInView] = useState<TReview | null>(null);
  const { reviews } = useReviews({ maxReviews });

  // scrolls enough to show the next review
  const getScrollAmount = () => (window.innerWidth < 960 ? 320 : 416);

  return (
    <StyledReviewsWall>
      <div className="reviews" ref={reviewsDiv}>
        {reviews.map((review, reviewIndex) => (
          <ReviewCard key={reviewIndex} review={review} onClickReview={() => setReviewInView(review)} />
        ))}
      </div>
      <div className="reviews__controller">
        <span
          className="scroll-reviews-back"
          onClick={() => reviewsDiv.current?.scrollBy({ left: -getScrollAmount(), behavior: "smooth" })}
        >
          <IconCaret />
        </span>
        <span
          className="scroll-reviews-forward"
          onClick={() => reviewsDiv.current?.scrollBy({ left: getScrollAmount(), behavior: "smooth" })}
        >
          <IconCaret />
        </span>
      </div>
      <div className="read-more-reviews">
        <a href={googleReviewsURL} target="_blank">
          Read more reviews
        </a>
      </div>

      {reviewInView && <ReviewModal review={reviewInView} onClickCloseModal={() => setReviewInView(null)} />}
    </StyledReviewsWall>
  );
};

const ReviewModal = ({ review, onClickCloseModal }: { review: TReview; onClickCloseModal: () => void }) => {
  return (
    <ModalBackground onClick={onClickCloseModal}>
      <StyledReviewModal>
        <b className="author">
          <div className="reviewer-picture">
            <ImageInContext alt={review.reviewerName} src={review.reviewerPhotoUrl} />
          </div>
          {review.reviewerName}
        </b>
        <div className="main">
          <div className="stars-container">
            <div className="stars hide-mobile">
              {[1, 2, 3, 4, 5].map((number) => (
                <ReviewStar key={number} />
              ))}{" "}
            </div>
            <div className="stars hide-widescreen">
              {[1, 2, 3, 4, 5].map((number) => (
                <ReviewStarMobile key={number} />
              ))}
            </div>
            <div className="date">{formatDistanceToNowStrict(new Date(review.createdAt))} ago</div>
          </div>
          <p className="body">{review.reviewText}</p>
        </div>
      </StyledReviewModal>
    </ModalBackground>
  );
};
const StyledReviewModal = styled.div`
  display: flex;
  margin: auto;
  justify-content: start;
  text-align: start;
  max-width: 720px;
  padding: 26.667px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  border-radius: 6.667px;
  border: 1px solid var(--Upsolve-Black, #101828);
  background: #ede8da;
  justify-content: space-between;
  cursor: pointer;
  gap: 8px;
  @media screen and (max-width: ${(props) => props.theme.breakpoints[700]}) {
    padding: 14px;
  }

  .main {
    display: flex;
    width: 100%;
    flex: 1;
    flex-direction: column;
    gap: 16px;
  }
  .body {
    font-size: 18px;
    flex: 1;
    @media screen and (max-width: ${(props) => props.theme.breakpoints[700]}) {
      font-size: 14px;
    }
  }
  .author {
    font-size: 21px;
    width: 100%;
    display: flex;
    align-items: center;
    @media screen and (max-width: ${(props) => props.theme.breakpoints[700]}) {
      font-size: 16px;
    }
  }
  .reviewer-picture {
    height: 32px;
    min-height: 32px;
    width: 32px;
    min-width: 32px;
    margin-right: 6px;
    border-radius: 16px;
    overflow: hidden;
    background: ${(props) => props.theme.colors.white[500]};
    img {
      height: 100%;
      width: auto;
    }
  }
  .stars-container {
    display: flex;
    gap: 6px;
    font-size: 18px;
    align-items: center;
    @media screen and (max-width: ${(props) => props.theme.breakpoints[700]}) {
      font-size: 14px;
    }
  }
`;

const ReviewCard = ({ review, onClickReview }: { review: TReview; onClickReview: () => void }) => {
  const RenderedBody = () => {
    if (review.reviewText.length > 240)
      return (
        <p className="body">
          {review.reviewText.slice(0, 227)}... <b>Read More</b>
        </p>
      );
    else return <p className="body">{review.reviewText}</p>;
  };

  return (
    <StyledReviewCard onClick={onClickReview}>
      <div className="main">
        <div className="stars hide-mobile">
          {[1, 2, 3, 4, 5].map((number) => (
            <ReviewStar key={number} />
          ))}
        </div>
        <div className="stars hide-widescreen">
          {[1, 2, 3, 4, 5].map((number) => (
            <ReviewStarMobile key={number} />
          ))}
        </div>

        <RenderedBody />
      </div>
      <div className="footer">
        <b className="author">
          <div className="reviewer-picture">
            <ImageInContext alt={review.reviewerName} src={review.reviewerPhotoUrl} />
          </div>
          {review.reviewerName}
        </b>
        <div className="body">{formatDistanceToNowStrict(new Date(review.createdAt))} ago</div>
      </div>
    </StyledReviewCard>
  );
};

const StyledReviewCard = styled.div`
  display: flex;
  width: 386px;
  height: 360px;
  padding: 26.667px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
  border-radius: 6.667px;
  border: 1px solid var(--Upsolve-Black, #101828);
  background: #ede8da;
  justify-content: space-between;
  cursor: pointer;

  @media screen and (max-width: ${(props) => props.theme.breakpoints[700]}) {
    width: 300px;
    height: 280px;

    padding: 14px;
  }

  .main {
    display: flex;
    flex-direction: column;
    gap: 6.667px;
    width: 350px;
    @media screen and (max-width: ${(props) => props.theme.breakpoints[700]}) {
      width: 260px;
    }
  }
  .footer {
    display: flex;
    gap: 4px;
    flex-direction: column;
  }
  .body {
    font-size: 18.667px;
    @media screen and (max-width: ${(props) => props.theme.breakpoints[700]}) {
      font-size: 14px;
    }
  }
  .author {
    font-size: 21px;
    display: flex;
    align-items: center;
    @media screen and (max-width: ${(props) => props.theme.breakpoints[700]}) {
      font-size: 16px;
    }
  }
  .location {
    font-size: 13px;
    @media screen and (max-width: ${(props) => props.theme.breakpoints[700]}) {
      font-size: 10px;
    }
  }
  .reviewer-picture {
    height: 32px;
    min-height: 32px;
    width: 32px;
    min-width: 32px;
    margin-right: 6px;
    border-radius: 16px;
    overflow: hidden;
    background: ${(props) => props.theme.colors.white[500]};
    img {
      height: 100%;
      width: auto;
    }
  }
`;

const StyledReviewsWall = styled.div`
  width: 100%;
  overflow: hidden;
  .reviews__controller {
    justify-content: center;
    padding: 30px;
    color: ${(props) => props.theme.colors.brand[500]};
    font-size: 28px;
    font-weight: 500;
    line-height: 125%;
    display: flex;
    align-items: center;
    gap: 14px;

    .scroll-reviews-back,
    .scroll-reviews-forward {
      height: 36px;
      width: 36px;
      border-radius: 18px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 3px solid ${(props) => props.theme.colors.brand[700]};
      background: white;
      cursor: pointer;

      svg {
        height: 16px;
        width: 16px;
        path {
          fill: ${(props) => props.theme.colors.brand[500]};
        }
      }
      &:hover {
        border: 3px solid ${(props) => props.theme.colors.brand[500]};
        background: ${(props) => props.theme.colors.brand[500]};
        svg {
          path {
            fill: white;
          }
        }
      }
    }
    .scroll-reviews-back {
      svg {
        transform: rotate(90deg);
      }
    }
    .scroll-reviews-forward {
      svg {
        transform: rotate(-90deg);
      }
    }
  }
  .reviews {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 30px;
    padding: 0px 40px;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    overflow-x: scroll;
    overflow-y: hidden;
    /* For some reason, the overflow div holding the reviews does not start the reviews at the beginning of the div. we have to increase padding with each resize in order to accomodate this */
    @media screen and (max-width: ${(props) => props.theme.breakpoints[900]}) {
      padding: 0px 96px;
    }
    @media screen and (max-width: ${(props) => props.theme.breakpoints[800]}) {
      padding: 0px 160px;
    }
    @media screen and (max-width: ${(props) => props.theme.breakpoints[700]}) {
      padding: 0px 240px;
    }
    @media screen and (max-width: ${(props) => props.theme.breakpoints[600]}) {
      padding: 0px 310px;
    }
    @media screen and (max-width: ${(props) => props.theme.breakpoints[500]}) {
      padding: 0px 380px;
    }
    @media screen and (max-width: ${(props) => props.theme.breakpoints[300]}) {
      padding: 0px 486px;
    }
  }
  .read-more-reviews {
    width: 100%;
    text-align: center;
  }
`;
